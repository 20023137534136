<template>
  <div class="div">
    <v-dialog v-model="dialog" persistent max-width="720">
      <template v-slot:activator="{ on, attrs }">
        <button
          v-bind="attrs"
          v-on="on"
          style="color: aliceblue"
          class="btn btn-postpone btn_color_green"
        >
          Сменить роль сотрудника
        </button>
      </template>
      <v-card v-if="dialog">
        <v-card-title v-if="title" class="delete-title delete-center ">
          <div>Сменить роль сотрудника</div>
        </v-card-title>
        <FormInputBlock>
          <div class="d-flex">
            <div
              class="d-flex flex-column-reverse mb-4 mt-4 "
              style="width: 50%"
            >
              <ValidationAutocompleteField
                :search-function="UsersRole"
                v-model="users"
                :disabled="true"
                class="ml-4 mt-4 unArrow"
                rules="required"
                label="Текущая роль"
                validate-name="текущая роль"
              />
              <ValidationAutocompleteField
                :search-function="Users"
                v-model="users"
                class="ml-4 "
                rules="required"
                label="Cотрудник"
                validate-name="сотрудник"
              />
            </div>
            <div class="d-flex mt-4 ml-4 mr-4" style="width: 50%">
              <ValidationAutocompleteField
                :search-function="newRole"
                rules="required"
                :disabled="!users"
                style="width: 100%"
                label="Новая роль"
                validate-name="новая роль"
                v-model="selectRole"
              />
            </div>
          </div>
        </FormInputBlock>

        <v-card-actions
          style="padding-bottom: 16px"
          class="d-flex justify-end "
        >
          <v-btn
            width="154"
            height="42"
            color="#95c23d"
            class="ma-2 btn btn_color_white"
            text
            @click="close"
          >
            Отменить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-green "
            :disabled="!selectRole"
            @click="realSave"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSuccessPopup" persistent max-width="720">
      <v-card v-if="showSuccessPopup">
        <v-card-title v-if="title" class="delete-title delete-center ">
          Подтверждение
        </v-card-title>
        <v-card-text class="delete-center" style="white-space: pre-wrap;">
          <span
            >Пользователь будет удален из детских групп, в которых он был
            назначен воспитателем.</span
          >
        </v-card-text>
        <v-card-actions
          style="padding-bottom: 16px"
          class="d-flex justify-end "
        >
          <v-btn
            width="154"
            height="42"
            color="#95c23d"
            class="ma-2 btn btn_color_white"
            text
            @click="close"
          >
            Отменить
          </v-btn>
          <v-btn
            width="154"
            height="42"
            color="#95c23d"
            class="ma-2 btn btn_color_white"
            text
            @click="close"
          >
            Отменить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-green " @click="realSave">
            Продолжить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getRoleGroupOrganizationsByIdRequest } from "@/helpers/api/role-groups";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import {
  getPersonnelIdKindergartensRequest,
  getPersonnelKindergartensRequest
} from "@/helpers/api/kindergartens";

export default {
  name: "ChangeParentPopup",
  data() {
    return {
      selectRole: null,
      dialog: false,
      showSuccessPopup: false,
      currentPersonnelRole: null,

      Users: async value => {
        const id = this.$store.getters.getPageQuery.id;

        return (
          await getPersonnelKindergartensRequest({
            id: id,
            query: {
              search: value
            }
          })
        ).data.results.map(function(el) {
          return {
            text: `${el.phone_number}, ${el.additional_fields[0].value ||
              "-"},  ${el.additional_fields[1].value || "-"}`,
            value: el.id
          };
        });
      },

      UsersRole: async () => {
        const id = this.$store.getters.getPageQuery.id;

        return (
          await getPersonnelKindergartensRequest({
            id: id
          })
        ).data.results.map(function(el) {
          return {
            text: `${el?.role == "30" ? "Администратор" : ""}${
              el?.role == "20" ? "Воспитатель" : ""
            }${el?.role == "80" ? "Другой персонал" : ""}`,
            value: el.id
          };
        });
      },
      newRole: async () => {
        const id =
          this.$store.getters.getKindergartenId ||
          this.$store.getters.getCurrentOrganization;

        return (
          await getRoleGroupOrganizationsByIdRequest({
            id: id
          })
        ).data.results
          .filter(el => el.role != this.currentPersonnelRole)
          .filter(el => [20, 30, 80].includes(el.role))
          .map(el => ({
            text: `${el?.role == "80" ? "Другой персонал" : ""}${
              el?.role == "20" ? "Воспитатель" : ""
            }${el?.role == "30" ? "Администратор" : ""}`,
            value: el.id
          }));
      }
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    saveFunction: {
      type: Function
    }
  },
  components: {
    ValidationAutocompleteField,
    FormInputBlock
  },

  computed: {
    currentOgr() {
      return this.$store.getters.getCurrentOrganization;
    },

    users: {
      get() {
        return this.$store.getters.getUsers;
      },
      set(newValue) {
        this.$store.commit("setUsersList", {
          listName: "users",
          users: newValue
        });
      }
    }
  },

  methods: {
    close() {
      this.dialog = false;
      this.showSuccessPopup = false;
      this.selectRole = null;
      this.user = null;
      this.$store.commit("clearPersonnelForm");
      this.$store.commit("clearUserPersonnel");
      this.$store.commit("CLEAR_KINDERGARTEN_PERSONNEL_LIST");
    },

    realSave() {
      if (
        this.currentPersonnelRole == 20 &&
        this.dialog != false &&
        this.showSuccessPopup == false
      ) {
        this.dialog = false;
        this.showSuccessPopup = true;

      } else {
        localStorage.setItem("newRole", this.selectRole);
        this.showSuccessPopup = false;
        this.saveFunction();
        this.close();
      }
    }
  },
  watch: {
    users() {
      if (this.users) {
        let id = this.users;
        let kindergartens_pk =
          localStorage.getItem("organization") != 1
            ? localStorage.getItem("organization")
            : localStorage.getItem("KindergartenId");

        return new Promise((resolve, reject) => {
          getPersonnelIdKindergartensRequest({ id, kindergartens_pk })
            .then(response => {
              this.currentPersonnelRole = response.data.role;

              this.$store.commit("SET_KINDERGARTEN_PERSONNEL_ID_LIST", {
                listName: "KindergartenPersonnelList",
                payload: response.data
              });
              resolve(response);
            })
            .catch(error => reject(error));
        });
      }
    },
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });

        this.isDateLoaded = true;
        this.$store.dispatch("fetchPersonnelList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  created() {
    this.$store.dispatch("fetchPersonnelList", {
      id: this.$route.params.id
    });
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}

.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
body .v-input .v-select__slot .v-input__append-inner {
  opacity: 0;
}
</style>
